import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `Garnier`,
	heading: 'Moonlight Cinema Promo.',
	emphasisedText: 'A form based promotion with automated Digital prize fulfilment',
	text: [
		'For the Garnier Moonlight Cinema Promotion, we helped build out the promotional microsite by reskinning our go-to template for form based competitions.', 
		'Once entrants completed their form and uploaded a valid receipt, they received their prizes via an automated eDM blast with their digital cinema vouchers attached.'
	],
	numbers: {
		Entrants: '3000',
		Winners: '151',
		'In prizes': '$10K',
	},
};

export default function Garnier() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<StaticImage alt="Hero image" src="../images/Garnier@3x.png" width={500} height={500} />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
